<template>
  <el-table :data="deliProdList" :summary-method="getSummaries" border class="deli_prod" show-summary @selection-change="handleSelectionChange">
    <el-table-column label="序号" width="48" align="center">
      <template v-slot="scope">
        {{ scope.$index + 1 }}
      </template>
    </el-table-column>
    <el-table-column label="采购合同号" width="150">
      <template v-slot="scope">
        <el-input disabled size="small" v-model="scope.row.podr_no" maxlength="10" show-word-limit placeholder="暂无"> </el-input>
      </template>
    </el-table-column>
    <el-table-column label="销售合同号" width="150">
      <template v-slot="scope">
        <el-input disabled size="small" v-model="scope.row.scon_no" maxlength="10" show-word-limit placeholder="暂无"> </el-input>
      </template>
    </el-table-column>
    <el-table-column label="起始箱号" width="150">
      <template v-slot="scope">
        <el-input v-model="scope.row.tran_prod_ctns" disabled maxlength="10" placeholder="暂无起始箱号" show-word-limit size="small"></el-input>
      </template>
    </el-table-column>
    <el-table-column label="结束箱号" width="150">
      <template v-slot="scope">
        <el-input v-model="scope.row.tran_prod_ctne" disabled maxlength="10" placeholder="暂无结束箱号" show-word-limit size="small"></el-input
      ></template>
    </el-table-column>
    <el-table-column label="我司货号" width="150">
      <template v-slot="scope">
        <el-input disabled size="small" v-model="scope.row.prod_no" maxlength="10" show-word-limit placeholder="暂无我司货号"> </el-input
      ></template>
    </el-table-column>
    <el-table-column label="客户货号" width="150">
      <template v-slot="scope">
        <el-input v-model="scope.row.prod_cust_no" disabled maxlength="10" placeholder="暂无客户货号" show-word-limit size="small"></el-input
      ></template>
    </el-table-column>
    <el-table-column label="原始订单号" width="150">
      <template v-slot="scope">
        <el-input v-model="scope.row.scon_cust_ono" disabled maxlength="10" placeholder="暂无原始订单号" show-word-limit size="small"></el-input
      ></template>
    </el-table-column>
    <el-table-column label="新订单号" width="150">
      <template v-slot="scope">
        <el-input v-model="scope.row.scon_cust_no" disabled maxlength="10" placeholder="暂无新订单号" show-word-limit size="small"></el-input>
      </template>
    </el-table-column>
    <el-table-column label="海关编码" width="150">
      <template v-slot="scope">
        <el-input disabled size="small" v-model="scope.row.prod_hscode" maxlength="9" show-word-limit placeholder="暂无海关编码"></el-input
      ></template>
    </el-table-column>
    <el-table-column label="海关品名中文" width="150">
      <template v-slot="scope">
        <el-input v-model="scope.row.prod_hsname" disabled maxlength="10" placeholder="暂无海关品名中文" show-word-limit size="small"></el-input
      ></template>
    </el-table-column>
    <el-table-column label="海关品名英文" width="150">
      <template v-slot="scope">
        <el-input v-model="scope.row.prod_ehsname" disabled maxlength="10" placeholder="暂无海关品名英文" show-word-limit size="small"></el-input
      ></template>
    </el-table-column>
    <el-table-column label="产品英文规格" width="150">
      <template v-slot="scope">
        <el-input v-model="scope.row.prod_espec" disabled maxlength="20" placeholder="暂无产品英文规格" show-word-limit size="small"></el-input
      ></template>
    </el-table-column>
    <el-table-column label="数量" width="200">
      <template v-slot="scope">
        <el-input disabled size="small" v-model="scope.row.prod_num" maxlength="100" show-word-limit placeholder="暂无数量"></el-input
      ></template>
    </el-table-column>
    <el-table-column label="数量单位" width="150">
      <template v-slot="scope">
        <el-input disabled size="small" v-model="scope.row.prod_unit" maxlength="10" show-word-limit placeholder="暂无数量单位"></el-input
      ></template>
    </el-table-column>
    <el-table-column label="装箱数" width="150">
      <template v-slot="scope">
        <el-input v-model="scope.row.prod_qpc" disabled placeholder="暂无装箱数" show-word-limit size="small"></el-input>
      </template>
    </el-table-column>
    <el-table-column label="箱数" width="150">
      <template v-slot="scope">
        <el-input disabled size="small" show-word-limit maxlength="50" v-model="scope.row.prod_box_num" placeholder="暂无箱数"></el-input
      ></template>
    </el-table-column>
    <el-table-column label="包装单位" width="150">
      <template v-slot="scope">
        <el-input v-model="scope.row.prod_box_unit" disabled maxlength="50" placeholder="暂无包装单位" show-word-limit size="small"></el-input
      ></template>
    </el-table-column>

    <el-table-column label="每箱毛重(KGS)" width="200">
      <template v-slot="scope">
        <el-input v-model="scope.row.prod_gweight" :rows="2" disabled maxlength="50" placeholder="暂无每箱毛重(KGS)" show-word-limit></el-input
      ></template>
    </el-table-column>
    <el-table-column label="总毛重(KGS)" width="200" prop="gweight_num">
      <template v-slot="scope">
        <el-input v-model="scope.row.gweight_num" disabled maxlength="50" placeholder="暂无总毛重(KGS)" show-word-limit size="small"></el-input
      ></template>
    </el-table-column>
    <el-table-column label="每箱净重(KGS)" width="200">
      <template v-slot="scope">
        <el-input v-model="scope.row.prod_nweight" disabled maxlength="50" placeholder="暂无每箱净重(KGS)" show-word-limit size="small"></el-input
      ></template>
    </el-table-column>
    <el-table-column label="总净重(KGS)" width="200">
      <template v-slot="scope">
        <el-input v-model="scope.row.nweight_num" :rows="2" disabled maxlength="50" placeholder="暂无总净重(KGS)" show-word-limit size="small"></el-input
      ></template>
    </el-table-column>
    <el-table-column label="长度(CM)" width="200">
      <template v-slot="scope">
        <el-input v-model="scope.row.prod_boxl" :rows="2" disabled maxlength="50" placeholder="暂无长度(CM)" show-word-limit size="small"></el-input
      ></template>
    </el-table-column>
    <el-table-column label="宽度(CM)" width="200">
      <template v-slot="scope">
        <el-input v-model="scope.row.prod_boxw" :rows="2" disabled maxlength="50" placeholder="暂无宽度(CM)" show-word-limit size="small"></el-input
      ></template>
    </el-table-column>
    <el-table-column label="高度(CM)" width="200">
      <template v-slot="scope">
        <el-input v-model="scope.row.prod_boxh" :rows="2" disabled maxlength="50" placeholder="暂无高度(CM)" show-word-limit size="small"></el-input>
      </template>
    </el-table-column>
    <el-table-column label="每箱体积（CBM)" width="200">
      <template v-slot="scope">
        <el-input v-model="scope.row.prod_vol" :rows="2" disabled maxlength="50" placeholder="暂无每箱体积（CBM)" show-word-limit size="small"></el-input
      ></template>
    </el-table-column>
    <el-table-column label="总体积（CBM)" width="200">
      <template v-slot="scope">
        <el-input v-model="scope.row.vol_num" :rows="2" disabled maxlength="50" placeholder="暂无总体积（CBM)" show-word-limit size="small"></el-input>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { get } from '@api/request';
import { optnAPI } from '@api/modules/optn';
import { stffAPI } from '@/api/modules/staff';
import { deliAPI } from '@/api/modules/deli';
import { BigNumber } from 'bignumber.js';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'SmplEditBomChild',
  props: {
    deliForm: {
      type: Object,
      required: true
    },
    isShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      smplFlag: 0,
      selectionsList: [],
      smplType: [
        { id: 1, label: '新款' },
        { id: 2, label: '老款' },
        { id: 3, label: '修改款' }
      ],
      selectFlag: false,
      beloType: [],
      smplQuotlist: [
        { id: 1, label: '打样' },
        { id: 2, label: '打样并报价' },
        { id: 3, label: '报价' }
      ],
      deliSmplMadeList: [],
      userList: [],
      deliProdList: []
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.getProdTypeList();
      this.getUser();
      this.getDeliProdList();
    },
    // 获取进仓单子表信息
    getDeliProdList() {
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      const id = props.form_id;
      get(deliAPI.getDeliById, { deli_id: id }).then(res => {
        this.deliProdList = res.data.data.form.deliProdList;
        for (let i = 0; i < this.deliProdList.length; i++) {
          this.deliProdList[i].vol_num = this.deliProdList[i].prod_box_num * this.deliProdList[i].prod_vol;
          this.deliProdList[i].vol_num = this.deliProdList[i].vol_num.toFixed(4);
          this.deliProdList[i].gweight_num = this.deliProdList[i].prod_box_num * this.deliProdList[i].prod_gweight;
          this.deliProdList[i].gweight_num = this.deliProdList[i].gweight_num.toFixed(2);
          this.deliProdList[i].nweight_num = this.deliProdList[i].prod_box_num * this.deliProdList[i].prod_nweight;
          this.deliProdList[i].nweight_num = this.deliProdList[i].nweight_num.toFixed(2);
        }
      });
    },
    //列总和
    getSummaries(param) {
      const { columns, data } = param;
      let totalList = [];
      for (let i = 0; i < 41; i++) {
        let temp = '';
        if (i === 0) {
          temp = '合计';
        } else {
          temp = '';
        }
        totalList.push(temp);
      }
      columns.forEach((column, index) => {
        let datas = data;
        let totalD = new BigNumber(0);
        let totalE = new BigNumber(0);
        let totalF = new BigNumber(0);
        let totalG = new BigNumber(0);
        for (let i in datas) {
          totalD = totalD.plus(datas[i].prod_box_num ? datas[i].prod_box_num : 0); //总箱数
          totalE = totalE.plus(datas[i].gweight_num ? datas[i].gweight_num : 0); //总毛重
          totalF = totalF.plus(datas[i].nweight_num ? datas[i].nweight_num : 0); //总净重
          totalG = totalG.plus(datas[i].vol_num ? datas[i].vol_num : 0); //总体积
        }
        totalD = totalD.toNumber(); //总箱数
        totalE = totalE.toNumber(); //总毛重
        totalF = totalF.toNumber(); //总净重
        totalG = totalG.toNumber(); //总体积
        totalList[16] = totalD;
        totalList[19] = this.helper.haveFour(totalE + '');
        totalList[21] = this.helper.haveFour(totalF + '');
        totalList[26] = this.helper.haveFour(totalG + '');
      });
      return totalList;
    },
    getUser() {
      get(stffAPI.getAllStffsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.userList = res.data.data;
          }
        })
        .catch(err => {
          this.$message.error(err.data.msg);
        });
    },
    // 获取产品类别
    getProdTypeList() {
      get(optnAPI.getOptnByPermId, { perm_id: 10009 })
        .then(res => {
          if (res.data.code === 0) {
            this.deliSmplMadeList = res.data.data.form.optn_cntt_list;
          }
        })
        .catch(() => {});
    },
    //选择框
    handleSelectionChange(selection) {
      this.selectionsList = [];
      this.selectionsList = selection;
      this.$emit('handleSelectionChange', this.selectionsList);
    }
  }
};
</script>

<style scoped>
/* .bac{
  border-radius: 5px;
  margin-top: 0px;
  border: rgb(255, 0, 0) 1px solid;
} */
.deli_prod {
  margin-top: 20px;
}
</style>
