import topic from '../topic';
export const deliAPI = {
  getDelis: topic.deliTopic + '/get_delis',
  getDeliById: topic.deliTopic + '/get_deli_by_id',
  deleteDeliByIds: topic.deliTopic + '/delete_deli_by_ids',
  editDeli: topic.deliTopic + '/edit_deli',
  addDeli: topic.deliTopic + '/add_deli',
  checkCreatDeli: topic.deliTopic + '/check_created_deli', // 查询出当前出运信息中未生成进仓单的报关明细
  exportExcel: topic.deliTopic + '/export_deli_excel_by_deli_id', // 生成excel

  getDeliProdList: topic.deliTopic + '/getDeliProdList', //获取进仓单汇总列表
  editDeliProdRemarkByDeliProdId: topic.deliTopic + '/editDeliProdRemarkByDeliProdId', //更改进仓单汇总
  exportDeliProd: topic.deliTopic + '/exportDeliProd' // 汇总导入excel
};
