<template>
  <div class="vg_wrapper" v-loading="loadingFlag">
    <div class="vd_edhea vg_button_group">
      <editHeader :isShow="isShow" :btn="btn" :strForm="deliForm" @openEdit="openEdit" @closeEdit="closeEdit" @submit="submit('deliForm')" />
      <div class="vd_export">
        <el-button type="primary" size="small" @click="buttonRefresh()" v-if="isShow">刷新</el-button>
        <el-button type="primary" size="small" @click="exportExcelPreview" v-if="isShow">预览excel</el-button>
        <el-button type="primary" size="small" @click="clickExportPartExcel" v-if="isShow">导出excel</el-button>
      </div>
      <div class="vd_export2" style="display: flex">
        <div class="vg_mr_8">
          <span style="margin-right: 2px">出运发票号:</span>
          <el-input size="small" style="width: 200px" :value="helper.transverseTranNo(deliForm.tran_no)" disabled show-word-limit></el-input>
        </div>
      </div>
    </div>
    <el-form class="form" ref="deliForm" :model="deliForm" :rules="rules" label-width="120px" size="mini" :disabled="isShow">
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="供应商简称" prop="supp_id">
            <el-select @change="suppIdChange" size="mini" filterable v-model="deliForm.supp_id" placeholder="请选择供应商简称">
              <el-option v-for="item in suppOptionList" :key="item.supp_id" :label="item.supp_abbr" :value="item.supp_id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="进仓编号" prop="deli_no">
            <el-input size="mini" maxlength="30" v-model="deliForm.deli_no" placeholder="请填写进仓编号" show-word-limit></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="经办人" :rules="[{ required: true }]">
            <el-select filterable v-model="deliForm.stff_name" placeholder="请选择经办人" size="mini" clearable>
              <el-option v-for="item in userList" :key="item.stff_id" :label="item.stff_name" :value="item.stff_id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="公司抬头" prop="cptt_aname">
            <el-select filterable v-model="deliForm.cptt_aname" placeholder="请选择公司抬头" size="mini" clearable>
              <el-option v-for="item in CpttsOptins" :key="item.cptt_id" :label="item.cptt_name" :value="item.cptt_id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="进仓日期" prop="deli_date">
            <el-date-picker v-model="deliForm.deli_date" type="date" placeholder="选择日期"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="离港日" prop="tran_spdate">
            <el-date-picker v-model="deliForm.tran_spdate" type="date" placeholder="选择日期"></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="货代简称" prop="inst_id">
            <!--@change="instCaddr"-->
            <el-select size="mini" filterable v-model="deliForm.inst_id" placeholder="请选择货代简称">
              <el-option v-for="item in instOptionList" :key="item.inst_id" :label="item.inst_abbr" :value="item.inst_id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="目的国" prop="cust_dcountry">
            <el-input maxlength="10" disabled v-model="deliForm.cust_dcountry" placeholder="请填写目的国" show-word-limit size="mini"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="目的港" prop="cust_dport">
            <el-input maxlength="10" disabled v-model="deliForm.cust_dport" placeholder="请填写目的港" show-word-limit size="mini"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="送货仓库" prop="cinst_abbr">
            <el-select @change="changeCinstAbbr" size="mini" filterable v-model="deliForm.inst_cid" placeholder="请选择货代简称">
              <el-option v-for="item in deliveryWarehouse" :key="item.id" :label="item.param1" :value="item.id"></el-option>
            </el-select>
            <!--<el-input maxlength="10" disabled v-model="deliForm.cinst_abbr" placeholder="暂无送货仓库" show-word-limit size="mini">-->
            <!--  <template slot="append">-->
            <!--    <el-link type="primary" :disabled="isShow" @click="dialogVisible = true">选择</el-link>-->
            <!--  </template>-->
            <!--</el-input>-->
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="送货联系人">
            <el-input maxlength="10" disabled v-model="deliForm.inst_ccontact" placeholder="请填写送货联系人" show-word-limit size="mini"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="送货电话">
            <el-input maxlength="10" disabled v-model="deliForm.inst_ccphone" placeholder="请填写送货电话" show-word-limit size="mini"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <el-form-item label="送货地址" prop="inst_caddr">
            <el-input type="textarea" :rows="2" placeholder="请输入送货地址" disabled v-model="deliForm.inst_caddr" size="mini" style="margin-bottom: 5px">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <div style="display: flex; margin-bottom: 8px">
            <div
              style="
                width: 117px;
                textalign: right;
                paddingright: 10px;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                box-sizing: border-box;
                margin-right: 12px;
              "
            >
              <div>送货资料</div>
              <el-link :disabled="isShow" class="vg_pointer" type="primary" @click="clickSelectClau(1)">选择</el-link>
            </div>
            <el-input type="textarea" placeholder="请输入送货资料" v-model="deliForm.clau_content" size="mini"></el-input>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <el-form-item label="备注" prop="requ_content">
            <el-input type="textarea" :rows="2" placeholder="请输入备注" v-model="deliForm.deli_remark" size="mini"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- <div class="vd_dash"></div> -->
      <el-row>
        <div>
          <RequList @handleSelectionChange="handleSelectionChange" :deliForm="deliForm"></RequList>
        </div>
      </el-row>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="false" :stffForm="stffForm" ref="userData"></inputUser>
        </el-col>
      </el-row>
    </el-form>
    <el-dialog title="机构信息" :visible.sync="dialogVisible" width="70%">
      <ImportInst @importInstChange="importInstChange"></ImportInst>
    </el-dialog>
    <el-dialog title="文本信息" :visible.sync="dialogClauInfoVisible" width="70%">
      <importClauInfo @importClauInfoChange="importClauInfoChange" />
    </el-dialog>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { deliAPI } from '@api/modules/deli';
import { stffAPI } from '@/api/modules/staff';
import { instAPI } from '@api/modules/inst';
import { suppAPI } from '@api/modules/supp';
import { userAPI } from '@api/modules/user';
import editHeader from '@/views/component/editHeader';
import inputUser from '@/views/component/inputUser';
import helper from '@assets/js/helper';
import RequList from './Componet/RequList.vue';
import importClauInfo from './Componet/importClauInfo.vue';
import { cpttAPI } from '@api/modules/comptitle';
import ImportInst from './Componet/ImportInst';
import { optnAPI } from '@api/modules/optn';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'DeliEditMain',
  components: {
    editHeader,
    inputUser,
    importClauInfo,
    RequList,
    ImportInst
  },
  data() {
    return {
      deliveryWarehouse: [],
      rules: {
        supp_id: [{ required: true, trigger: 'blur', message: ' ' }],
        deli_date: [{ required: true, trigger: 'blur', message: ' ' }],
        inst_id: [{ required: true, trigger: 'blur', message: ' ' }]
      },
      deliForm: {
        requ_no: null,
        supp_id: null,
        deli_state: 0,
        supp_ordr_total: null,
        cust_id: null,
        cust_dcountry: null, // 目的国
        cinst_id: null, //货代简称
        requ_sample: null,
        inst_cid: null,
        deli_no: null,
        requ_pedate: null,
        requ_stff_id: null,
        requ_content: null,
        tran_no: ''
      },
      show: false,
      isShow: true,
      btn: {
        edit: true
      },
      user_id: null, // 录入人id
      stffForm: {}, // 查询到的录入人信息
      loadingFlag: true,
      dialogVisible: false,
      dialogVisible1: false,
      title: '委托设计单',
      deliStppList: [],
      deliPhoneNo: '',
      deliStateList: [
        { id: 0, label: '未开始' },
        { id: 1, label: '进行中' },
        { id: 2, label: '已完成' }
      ],
      festSmplListCopy: [],
      suppOptionList: [], // 供应商信息
      selectList: [],
      deliFormList: [],
      instOptionList: [], // 送货信息
      showFlag: false,
      userList: [], // 经办人信息
      CpttsOptins: [], // 公司信息
      type: null,
      synchronizationFlag: false,
      dialogClauInfoVisible: false
    };
  },
  created() {
    this.initData();
  },
  watch: {},
  methods: {
    initData() {
      this.loadingFlag = false;
      this.getdeliInfo();
      this.getUser();
      this.getSupps();
      this.getInsts();
      this.getCpttsList();
      this.getDeliveryWarehouse();
    },
    getDeliveryWarehouse() {
      get(optnAPI.getOptnByPermId, { perm_id: 10029 })
        .then(res => {
          if (res.data.code === 0) {
            this.deliveryWarehouse = res.data.data.form.optn_cntt_list;
          }
        })
        .catch(err => console.error(err));
    },
    // 判断是否为出运刚生成的进仓单
    editStatus() {
      if (this.deliForm.create_time === this.deliForm.update_time) {
        this.isShow = false;
        this.$emit('isShow', this.isShow);
      }
    },
    // 查询送货仓库
    getInsts() {
      get(instAPI.getAllInstsV1, {
        page_no: 1
      }).then(res => {
        this.instOptionList = res.data.data.list;
      });
    },
    // 获取表单信息
    getdeliInfo() {
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      this.showFlag = props.showFlag;
      const id = props.form_id;
      get(deliAPI.getDeliById, {
        deli_id: id
      }).then(res => {
        this.deliForm = res.data.data.form;
        this.deliForm.tran_spdate = helper.toTime(this.deliForm.tran_spdate);
        this.user_id = res.data.data.form.user_id;
        this.user_name = res.data.data.form.stff_name;
        if (this.deliForm.deli_date === 0) {
          this.deliForm.deli_date = null;
        } else {
          this.deliForm.deli_date = helper.toTime(this.deliForm.deli_date);
        }
        if (this.deliForm.inst_cid === 0) {
          this.deliForm.inst_cid = null;
        }
        if (this.deliForm.inst_id === 0) {
          this.deliForm.inst_id = null;
        }
        if (this.deliForm.supp_id === 0) {
          this.deliForm.supp_id = null;
        }
        this.getUserById();
        this.editStatus();
      });
    },
    // 供应商简称选值
    suppIdChange() {
      console.log('this.deliForm.supp_id', this.deliForm.supp_id);
      for (let i = 0; i < this.suppOptionList.length; i++) {
        if (this.suppOptionList[i].supp_id === this.deliForm.supp_id) {
          this.deliForm.supp_abbr = this.suppOptionList[i].supp_abbr;
        }
      }
    },
    // 送货地址存值
    importInstChange(val) {
      this.deliForm.cinst_abbr = val.inst_abbr;
      this.deliForm.inst_cid = val.inst_id;
      this.deliForm.inst_cabbr = val.inst_abbr;
      this.deliForm.inst_ccontact = val.inst_contact;
      this.deliForm.inst_ccphone = val.inst_cphone;
      this.deliForm.inst_caddr = val.inst_addr;
      this.dialogVisible = false;
    },
    // 获取送货地址
    instCaddr(test) {
      for (let i = 0; i < this.instOptionList.length; i++) {
        if (test === this.instOptionList[i].inst_id) {
          this.deliForm.inst_caddr = this.instOptionList[i].inst_addr;
        }
      }
    },
    //选择文本信息
    clickSelectClau(val) {
      this.importInfoType = val;
      this.dialogClauInfoVisible = true;
    },
    //选中文本信息
    importClauInfoChange(row) {
      this.deliForm.clau_content = row.clau_content; //送货资料
      this.dialogClauInfoVisible = false;
    },
    //启用编辑
    openEdit() {
      this.isShow = false;
      this.$emit('isShow', this.isShow);
    },
    //撤销编辑
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.isShow = true;
          this.$emit('isShow', this.isShow);
          this.show = true;
          this.loadingFlag = false;
          this.getdeliInfo();
          this.getUser();
          this.getSupps();
          this.getInsts();
          this.getCpttsList();
          this.$message({
            type: 'info',
            message: '已撤销编辑!'
          });
        })
        .catch(err => console.error(err));
    },
    //提交表单
    submit(formName) {
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs[formName].validate(valid => {
            if (valid) {
              this.saveInfo();
            } else {
              return false;
            }
          });
        })
        .catch(err => console.error(err));
    },
    // 保存
    saveInfo() {
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      let deliForm = JSON.parse(JSON.stringify(this.deliForm));
      deliForm.deli_id = props.form_id; // 进仓单id
      // deliForm.supp_abbr = this.deliForm.supp_id; // 供应商简称
      deliForm.deli_no = this.deliForm.deli_no; // 进仓编号
      for (let i = 0; i < this.userList.length; i++) {
        if (this.userList[i].stff_name === this.deliForm.stff_name) {
          this.deliForm.stff_name = this.userList[i].stff_id;
        }
      }
      deliForm.deli_stff_id = this.deliForm.stff_name; // 经办人ID
      if (deliForm.deli_stff_id === null || deliForm.deli_stff_id === '') {
        this.$message({
          type: 'error',
          message: '未选择经办人！！'
        });
        return;
      }
      deliForm.cptt_aname = this.deliForm.cptt_aname; // 公司抬头
      let cptt_aid = null;
      for (let i = 0; i < this.CpttsOptins.length; i++) {
        if (this.CpttsOptins[i].cptt_name === deliForm.cptt_aname) {
          cptt_aid = this.CpttsOptins[i].cptt_id;
        }
      }
      deliForm.tran_spdate = parseInt(new Date(deliForm.tran_spdate).getTime() / 1000);
      deliForm.cptt_aid = cptt_aid; // 公司抬头ID
      deliForm.deli_date = helper.toTimeVal(this.deliForm.deli_date); // 进仓日期
      deliForm.inst_id = this.deliForm.inst_id; // 货代ID
      // deliForm.inst_cid = this.deliForm.inst_id; // 送货仓库ID
      deliForm.inst_ccontact = this.deliForm.inst_ccontact; // 送货人
      deliForm.inst_ccphone = this.deliForm.inst_ccphone; // 送货人电话
      deliForm.inst_caddr = this.deliForm.inst_caddr; // 送货地址
      deliForm.clau_content = this.deliForm.clau_content; // 送货资料
      deliForm.deli_remark = this.deliForm.deli_remark; // 备注
      post(deliAPI.editDeli, deliForm)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            this.isShow = true;
            this.$emit('isShow', this.isShow);
            this.loadingFlag = false;
            this.initData();
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData();
          }
        })
        .catch(err => console.error(err));
    },
    // 查询经办人
    getUser() {
      get(stffAPI.getAllStffsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.userList = res.data.data;
          }
        })
        .catch(err => console.error(err));
    },
    // 根据录入人id查询录入人信息
    getUserById() {
      get(userAPI.getUserById, {
        user_id: this.user_id
      })
        .then(res => {
          if (res.data.code === 0) {
            this.stffForm = res.data.data.form;
          }
        })
        .catch(err => console.error(err));
    },
    // 刷新按钮
    buttonRefresh() {
      this.loadingFlag = false;
      this.getdeliInfo();
      this.getUser();
      this.getSupps();
      this.getInsts();
      this.getCpttsList();
    },
    //取消
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.isShow = true;
          this.$emit('isShow', this.isShow);
          this.loadingFlag = false;
          this.getdeliInfo();
          this.getUser();
          this.getSupps();
          this.getInsts();
          this.getCpttsList();
          //this.resetForm("deliForm");
          this.$router.go(-1);
          this.$message({
            type: 'info',
            message: '已取消新增!'
          });
        })
        .catch(err => console.error(err));
    },
    // 获取供应商信息
    getSupps() {
      get(suppAPI.getSuppsV1).then(res => {
        this.suppOptionList = res.data.data;
      });
    },
    // deliCancel
    deliCancel() {
      this.dialogVisible1 = false;
    },
    // 获取公司抬头
    getCpttsList() {
      get(cpttAPI.getAllCpttsV1, {}).then(res => {
        this.CpttsOptins = res.data.data;
      });
    },
    // 选中值
    handleSelectionChange(val) {
      let value = JSON.parse(JSON.stringify(val));
      this.selectList = value;
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //导出excel
    clickExportPartExcel() {
      this.$confirm('确定下载Excel?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const str = this.deliForm.deli_id;
          get(deliAPI.exportExcel, { deli_id: str, type: 1 })
            .then(res => {
              if (res.data.code === 0) {
                this.helper.downloadItemExcel(res.data.data.pdf_url, `${res.data.data.fileName}.xls`);
                return;
              }
              this.$message.error(res.data.msg);
            })
            .catch(err => console.error(err));
        })
        .catch(err => console.error(err));
    },
    exportExcelPreview() {
      const str = this.deliForm.deli_id;
      get(deliAPI.exportExcel, { deli_id: str, type: 0 })
        .then(res => {
          if (res.data.code === 0) {
            if (process.env.NODE_ENV === 'production') {
              window.open('/erpfile' + res.data.data.pdf_url + '.pdf');
            } else {
              window.open('/file' + res.data.data.pdf_url + '.pdf');
            }
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    changeCinstAbbr(val) {
      let { param2, param4, param5 } = this.deliveryWarehouse.find(({ id }) => id === val);
      this.deliForm.inst_ccontact = param4;
      this.deliForm.inst_ccphone = param5;
      this.deliForm.inst_caddr = param2;
    }
  }
};
</script>

<style scoped lang="scss">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader ::v-deep .el-upload--text {
  width: 136px;
  height: 136px;
}

.avatar-uploader .el-upload:hover {
  border-color: $color-primary;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: $color-uploader-icon;
  width: 136px;
  height: 136px;
  line-height: 136px;
  text-align: center;
}

.avatar {
  width: 136px;
  height: 136px;
  display: block;
}

.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}

.vd_bort {
  border-top: dashed 1px #dcdfe6;
  padding-top: 20px;
}

.vd_mrt1 {
  margin-top: 1px;
}

.vd_edhea {
  position: relative;
}

.vd_export {
  position: absolute;
  top: 0;
  left: 65px;
}

.vd_export1 {
  position: absolute;
  top: 0;
  left: 120px;
}

.vd_export3 {
  position: absolute;
  top: 0;
  left: 255px;
}

.vd_export2 {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 120px;
}

::v-deep .el-upload--text {
  height: 300px;
  width: 300px;
}

::v-deep .el-upload-dragger {
  width: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vd_dash {
  width: 100%;
  height: 1px;
  border-top: 1px dashed #e9e9e9;
  margin: 10px 0;
}

.el-form-item {
  margin-bottom: 3px;
}
</style>
