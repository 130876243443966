import topic from '../topic';
import { getNoCatch } from '@api/request';
export const instAPI = {
  getInsts: topic.instTopic + '/get_insts',
  getInstById: topic.instTopic + '/get_inst_by_id',
  addInst: topic.instTopic + '/add_inst',
  editInst: topic.instTopic + '/edit_inst',
  deleteInstByIds: topic.instTopic + '/delete_inst_by_ids',
  getAllInstsV1: topic.instTopic + '/get_all_insts_v1',
  getRichErpInst: params => getNoCatch(`${topic.instTopic}/getInstituteByRichErp`, params)
};
