import topic from '../topic';
export const clauAPI = {
  getClaus: topic.clauTopic + '/get_claus',
  get_all_claus_v2: topic.clauTopic + '/get_all_claus_v2',
  addClau: topic.clauTopic + '/add_clau',
  getClauById: topic.clauTopic + '/get_clau_by_id',
  editClau: topic.clauTopic + '/edit_clau',
  deleteClauByIds: topic.clauTopic + '/delete_clau_by_ids',
  getAllClausV1: topic.clauTopic + '/get_all_claus_v1'
};
