import topic from '../topic';
export const userAPI = {
  getUsers: topic.userTopic + '/get_users',
  getAllUsersV1: topic.userTopic + '/get_all_users_v1',
  getUserById: topic.userTopic + '/get_user_by_id',
  addUser: topic.userTopic + '/add_user',
  editUser: topic.userTopic + '/edit_user',
  deleteUserByIds: topic.userTopic + '/delete_user_by_ids',
  userLogin: topic.userTopic + '/login',
  userLogout: topic.userTopic + '/logout',
  logUserId: topic.userTopic + '/log_user_id',
  logMenu: topic.userTopic + '/log_menu',
  resetUserPass: topic.userTopic + '/reset_user_pass',
  export_user: topic.userTopic + '/export_user', //导出
  restoreUser: topic.userTopic + '/restore_user', //恢复账号
  getMyUser: topic.userTopic + '/get_my_user', //判断账号
  getVersion: topic.userTopic + '/get_version' //判断版本
};
